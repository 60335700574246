.fileInput {
    display: none;
    margin-bottom: 10px;
  }
  
  .fileInputLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: #1976d2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .fileInputLabel:hover {
    background-color: #1565c0;
  }
  
  .fileInputIcon {
    margin-right: 8px;
  }
  .imageListItem {
    position: relative!important;
    border: 1px solid silver; 
    padding:5px;
  }
  .imageWrapper {
    position: relative;
  }
  
  .deleteIcon {
    position: absolute!important;
    top: 2px;
    right: 2px;
    color: white!important;
    background-color: rgba(0, 0, 0, 0.7)!important;
    border-radius: 50%;
    padding: 2px!important;
    z-index: 999;
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }

  

  .interactive_zoom_container {  
    top:50%;
    left:0;
    transform: translate(-50%,-50%);
    background-color: #fff;
    overflow: hidden;   
    transition: transform 0.3s ease; /* Smooth transition for zooming */
    /* width:95%; */
    padding: 5px!important;
    margin: 10px;
    height: 250px;
    overflow: hidden;
   }
  
  .interactive_zoom_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;    
  }
  
   