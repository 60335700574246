:root{ --main-color: #FC2638; }
.serviceBox{
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 30px 20px 30px 30px;
    border-radius: 20px;
    box-shadow: 5px 0 12px rgba(0,0,0,0.2),-7px 15px var(--main-color);
    position: relative;
    z-index: 1;
}
.serviceBox:before{
    content: '';
    background-color: var(--main-color);
    height: 70%;
    width: 15px;
    position: absolute;
    left: 0;
    bottom: 5px;
    clip-path: polygon(0% 0%,100% 15%,100% 85%,0% 100%);
}
.serviceBox .service-icon{
    color: var(--main-color)!important;
    // font-size: 40px!important;
    margin: 0 auto 20px!important;
}
.serviceBox .title{
    color: var(--main-color);
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0 0 15px;
}
.serviceBox .description{
    color: #777;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    margin: 0;
}
.serviceBox .btncon{
    display:flex;
    justify-content: end;
    padding-top:10px;
}
.serviceBox.yellow{ --main-color: #ecc204; }
.serviceBox.blue{ --main-color: #06CDEA; }
.serviceBox.darkblue{ --main-color: #045582; }
@media only screen and (max-width: 1199px){
    .serviceBox{ margin: 0 0 15px; }
}