.container{
  display: flex;
  flex-direction: column;   
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  gap: 20px;
}
.iconcon{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    height: 80px;
}
.iconcon img{
  aspect-ratio: 3/2;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.headingcon{
    display: flex;
    justify-content: center;    
    gap: 5px;
}
.headingcon :nth-child(2){
    font-weight: 400;
}

.imgcon{
  width: 100%;
  height: 350px;
  overflow: hidden;
}
.imgcon img{
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.descheading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoheading{
  font-size: xx-large;
}
.infotext{
  padding-left: 30px;
  /* padding-right: 20px; */
  font-weight: 400;
  font-size: 20px;
}
.btncon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.getStartedBtn {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  border: none;
  border-radius: 30px;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.getStartedBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.getStartedBtn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
