.paperWithBorderTask {
    border-left: 6px solid #FF909F; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }
  .paperWithBorderPen {
    border-left: 6px solid #a47eb9; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }
  .paperWithBorderCmp {
    border-left: 6px solid #FFAA83; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }

  li{
    bottom:5px;
    margin-bottom: 5px;
  }