.toggle-switch {
    position: relative;
    width: 60px;
    display: inline-block;
    text-align: left;
    // top: 8px;
  }
  
  .toggle-switch.small {
    width: 40px;
  }
  
  .toggle-switch-checkbox {
    display: none;
  }
  
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 20px;
    margin: 0;
    transition: background-color 0.3s ease-in;
  }
  
  .toggle-switch.small .toggle-switch-label {
    border-radius: 12px;
  }
  
  .toggle-switch-label:active {
    box-shadow: 0 0 5px #888;
  }
  
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in;
  }
  
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  
  .toggle-switch.small .toggle-switch-inner:before,
  .toggle-switch.small .toggle-switch-inner:after {
    height: 18px;
    line-height: 18px;
    font-size: 12px;
  }
  
  .toggle-switch-inner:before {
    content: attr(data-yes);
    padding-left: 10px;
    background-color: #4cd964;
    color: white;
  }
  
  .toggle-switch-inner:after {
    content: attr(data-no);
    padding-right: 10px;
    background-color: #fff;
    color: #999;
    text-align: right;
  }
  
  .toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 34px;
    border: 2px solid #ddd;
    border-radius: 20px;
    transition: all 0.3s ease-in;
  }
  
  .toggle-switch.small .toggle-switch-switch {
    width: 12px;
    margin: 3px;
    right: 24px;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
  }
  
  .toggle-switch-checkbox:disabled + .toggle-switch-label {
    opacity: 0.5;
    cursor: not-allowed;
  }
  