.main{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.con{
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.menucon{
    display: flex;
    justify-content: end;
    /* align-items: center; */
}
.num{
  border-radius: 20px;
  background-color: #03346E;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  font-weight: 500!important;
}
.issueinfo{
    font-weight: 500;
    color: #dc3545;
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ellipsis (...) when content overflows */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
}
.dueAmount {
  color: #dc3545; /* Red */
}
.imgcon{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border-color: #03346E;
  padding: 5px;
  background-color: whitesmoke;
  overflow: hidden;
}
.imgcon img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.delcon {
    display: flex;
    gap:5px;
  }
  
  .modalcon{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 95%;
    padding: 5px;
  }
  .times{
    height: 24px;
    width: 24px;
    font-size: large;
    background-color: #dc3545;
    color: #fff;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .mcon{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mcon1{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mimgcon{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    border-color: #03346E;
    padding: 5px;
    background-color: whitesmoke;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 3/2;
    transform: scale(1);
  }