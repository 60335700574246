/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

html,
body {
  height: 100%;
  background-color: #FFE9FF!important;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes the full height of the viewport */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); /* Safe area insets for mobile */

}

.header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure header is above other content */
  padding: 5px;
  padding-left: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.footer {
  margin-top: auto; /* Push footer to the bottom */
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: env(safe-area-inset-bottom);
}

.content {
  flex: 1;
  overflow-y: auto; /* Allow content to scroll if necessary */
  display: flex;
  flex-direction: column; 
}

.avatar{
  display: flex;
  justify-content: center;
  align-items: center;
}
.namecon{
  display: flex;
  flex-direction: column!important;
  justify-content: flex-start!important;  
  gap:5px;
}
.namecon :nth-child(1){
  font-weight: 600;  
}
.namecon :nth-child(2){
  font-weight: 400;
  font-size: small;
  color:#9C9D9F;
}
.notifycon{
  display: flex;
  justify-content: end;
  align-items: center;
}
 
.menucon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;  
  color: #fff !important;
  padding: 3px;  
}
.paper1{
  background-color: #FF909F!important;
}
.paper2{
  background-color: #a47eb9!important;
}
.paper3{
  background-color: #FFAA83!important;
}
.menucon :nth-child(1){
  font-size: large;
}
.divider {
  border-top: 2px solid whitesmoke; /* Initial color */
  width: 100%; /* Set the width as needed */
  /* transition: border-color 1s ease-in-out; */

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition for transform and opacity */
  transform-origin: center;
  opacity: 0.7; /* Initial opacity */
  transform: scaleX(0);
}

.activeDivider {
  border-width: 2px;
  /* border-color: #0080D5; */
  border-color: blue;
  transform: scaleX(1); /* Scale transform on active */
  opacity: 1; /* Full opacity on active */
}

.taskheading{
  padding: 10px;   
}
.tasklistcon{
  display: flex;
  flex-direction: column!important; 
  overflow-y: auto;
  /* height: 60vh; */
  padding: 10px;  
  padding-top: 0px;
  gap: 8px;
  margin-bottom: 30px;
  
}
.paperWithBorderTask {
  border-left: 6px solid #FF909F; /* Adjust color and width as needed */  
  padding: 10px;
}
.paperWithBorderPen {
  border-left: 6px solid #a47eb9; /* Adjust color and width as needed */  
  padding: 10px;
}
.paperWithBorderCmp {
  border-left: 6px solid #FFAA83; /* Adjust color and width as needed */  
  padding: 10px;
}

.datainfo{
  text-align: end;
  font-size: small;
  padding: 10px;
  color: gray;
  font-weight: bolder;
}