/* Header.module.css */
:root {
    --White: #eaeaea;
    --red: #F5004F;
    --black: #222;
    --ft-bg: #004658;
}
* {
    margin: 0;
    padding: 0;

    /* background: var(--White)!important; */

}
body {
    background: var(--White)!important;
    color: var(--black);
}

.menu {    
    background: transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 100;
}

.logo {
    font-size: 25px;
    color: var(--black);
    text-decoration: none;
    font-weight: 800;
    opacity: 0;
    animation: slideRight 1s ease forwards;
}

.logo span {
    color: var(--red);
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly!important;
    align-items: center;
    gap: 30px;
}

.navbar a {
    font-size: 18px;
    color: var(--black);
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s;
    opacity: 0;
    animation: slideTop 0.5s ease forwards;
    animation-delay: calc(0.2s * var(--i));
}

.navbar a:hover {
    color: var(--red);
}
 
.homeContent h1 {
    font-size: 50px;
    line-height: 1.2;
    color: var(--black);
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    animation-delay: 1.3s;
}

.homeContent h1 span {
    color: var(--red);
}

.homeContent h4 {
    color: var(--black);
    animation: slideRight 1s ease forwards;
    animation-delay: 1.3s;
}

.homeContent p {
    font-size: 16px;
    margin: 15px 0 30px;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 1.6s;
}

.homeContent .btn {
    padding: 10px 28px;
    background: var(--red);
    border: 2px solid var(--red);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: var(--White);
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 600;
    transition: 0.5s;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: 2s;
}

.homeContent .btn:hover {
    background-color: transparent;
    color: var(--red);
}

.rmbs1{    
    position: relative;
    width: 100%;
    height: 400px; 
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;    
    box-sizing: border-box;
    opacity: 0;
    animation: zoomOut 1s ease forwards;
    animation-delay: 1.6s;
 }

.rmbs2{
    position: relative;
    width: 100%;
    height: 100%; 
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background-color: var(--White);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;   
}

.rmbs1 img {
    width: 100%;
    height: 60%;
    object-fit: contain;
    aspect-ratio: 3/2;
    opacity: 0;
    animation: img 1s ease forwards;
    animation-delay: 2s;
}
.imgcon{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.imgcon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 3/2;
}

.footerwavesvg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}
.footerwavepath {
    fill: var(--White);
}
.contactcon{
    display: flex;
    justify-content: space-evenly;
}
.coninfo{
  display: flex;
  align-items: center;
  gap: 5px;
}
.coninfosub{
  display: flex;
  flex-direction: column;  
}
.coninfosub h5{ color: #cfccdf;}
.conicon{
    color: var(--red);
    font-size: 30px;
}
 
.fotimgcon{
  width: 60px;
  height: 60px;
  overflow: hidden;  
}
.fotimgcon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.links{
   display: flex;
   flex-direction: column;
   gap: 5px; 
}
.links h5{
    color: #cfccdf;
    cursor: pointer;
}

.links h5:hover {
    background-color: transparent;
    color: var(--White);
    text-decoration: underline;
}
 
@media only screen and (max-width: 600px) {
    /* Styles for phones */
    .navbar{
        gap: 10px;
    }
    .contactcon{
        display: flex;
        flex-direction: column!important;
        gap: 10px;
     }
     
     .mcon{
        display: flex;
        gap: 10px;       
     }
    .conicon{
        font-size: 20px;
    }
  }

/* Keyframes */
@keyframes slideRight {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes zoomOut {
    0% {
        transform: scale(1.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes img {
    0% {
        transform: translate(300px, -300px) rotate(0deg);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
    }
}
@keyframes slideTop {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideSci {
    0% {
        transform: translateX(-100px) rotate(45deg);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
