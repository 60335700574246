.emain{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
}
.eimgcon{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }    
    
}



h1{
    color:#F5004F;
    text-align: center;
}

.ebtncon{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
}

.einfocon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    text-align: center;
  }

  .ebtnback{
        position: absolute;
        width:40px;
        height: 40px;
        background-color: #F5004F;
        color: #fff;
        border-radius: 50%;
        z-index: 999;
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
        top: 10px;
        left: 10px;
        text-align: center;
        padding-left: 5px;
    }



    /* Style the anchor as a button */
a.button-like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #17a2b8; /* Info color (Bootstrap color scheme) */
    color: white;
    padding: 12px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    border: none;
    border-radius: 4px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Full width */
    box-sizing: border-box;
}

/* Add hover effect */
a.button-like:hover {
    background-color: #138496; /* Darker shade of info color */
}

/* Adjust icon and text spacing */
a.button-like svg {
    margin-right: 8px; /* Space between icon and text */
    font-size: 1.2em; /* Adjust icon size */
}
