/* CSS */
.loader_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8); /* Optional: Add a background to make the loader stand out */
    z-index: 9999; /* Ensures the loader stays above other content */
  }
  
  .loader {
    width: 40px;
    aspect-ratio: 1;
    color: #f03355;
    position: relative;
    background: radial-gradient(10px, currentColor 94%, #0000);
  }
  
  .loader:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background:
      radial-gradient(9px at bottom right, #0000 94%, currentColor) top left,
      radial-gradient(9px at bottom left, #0000 94%, currentColor) top right,
      radial-gradient(9px at top right, #0000 94%, currentColor) bottom left,
      radial-gradient(9px at top left, #0000 94%, currentColor) bottom right;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    animation: l18 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
  
  @keyframes l18 {
    33% {
      inset: -10px;
      transform: rotate(0deg);
    }
    66% {
      inset: -10px;
      transform: rotate(90deg);
    }
    100% {
      inset: 0;
      transform: rotate(90deg);
    }
  }
  