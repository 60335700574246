.heading{
  padding: 10px;
  color:#000;
  font-size: large;
  font-weight: 600;
}
.iconcon{
    display: flex;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column!important;
    gap: 5px;
}
.iconcon div{
  width: 45px;
  height: 45px;
  background-color: #E9C46A;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
.iconcon h5{ 
 text-align: center;
}

.iconcolor{
  color:#4B2E2D
}
