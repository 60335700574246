* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html,
  body {
    height: 100%;
    background-color: #fff!important;
  }
  
   
  .bckarwcon{
    display: flex;
    align-items: center;
  }
  .bckarow{
    color: #007667;
    font-size: xx-large!important;
  }
  .hading{
    align-self: center;
    text-align: left;
    font-weight: 500;
    font-size: large;
    text-decoration: underline;
    font-style: italic;
    padding-left: 10px!important;
  }
  .btncon{
    display: flex;
    justify-content: end;
    align-items: center;
  }