* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html,
  body {
    height: 100%;
    background-color: #fff!important;
  }
      
  .bckarwcon{
    display: flex;
    align-items: center;
  }
  .bckarow{
    color: #007667;
    font-size: xx-large!important;
  }
  .hading{
    align-self: center;
    text-align: left;
    font-weight: 500;
    font-size: large;
    text-decoration: underline;
    font-style: italic;
    padding-left: 10px!important;
  }
  .btncon{
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .tasklistcon{
    display: flex;
    flex-direction: column!important; 
    overflow-y: auto;
    height: 75vh;
    padding: 10px;  
    padding-top: 0px;
    gap: 8px;
    margin-bottom: 30px;
  }
  .paperWithBorderTask {
    border-left: 6px solid #FF909F; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }
  .paperWithBorderPen {
    border-left: 6px solid #a47eb9; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }
  .paperWithBorderCmp {
    border-left: 6px solid #FFAA83; /* Adjust color and width as needed */
    /* padding-block-end: 10px; Optional: Adjust padding for spacing */
    padding: 10px;
  }

  .dateinfo{
    display: flex;
    gap:5px;
    align-items: center!important;
  }
  .dateinfo h5{
    color:gray;
  }
  .drawerclose{  
    margin-top: 10px; /* Push footer to the bottom */
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;    
  }