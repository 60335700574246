.tc-main-heading{
   text-align: center;
}

.tc-info{
    padding: 10px;
   
    p{
        line-height: 1.5rem;
   }
   
   li{
        line-height: 1.5rem;
    }
 }