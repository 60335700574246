.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 0px;
  }
  
  /* .toggleWrapper {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
  } */
  .toggleWrapper {
    display: flex;
    position: relative;
    width: 100%;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #c0c0c0;
  }
  
  /* Optional: For a dynamic, animated gradient effect */
  @keyframes gradientAnimation {
    0% {
      background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    }
    50% {
      background: linear-gradient(135deg, #e0e0e0, #f0f0f0);
    }
    100% {
      background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    }
  }
  
  .toggleWrapper {
    animation: gradientAnimation 5s ease infinite;
  }
  .toggleButton {
    flex: 1;
    padding: 5px 5px;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1;
    user-select: none;
  }
  
  .toggleButton:hover {
    color: #fff;
  }
  
  .active {
    color: #fff;
  }
  
  /* .switch {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 33.33%;
    background-color: #03346E;
    transition: transform 0.3s ease;
    z-index: 0;
  } */

  .switch {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 33.33%;
    background: linear-gradient(135deg, #03346E, #0052cc); /* Gradient effect for a more vibrant look */
    transition: transform 0.3s ease, background 0.3s ease;
    z-index: 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a more 3D effect */
  }
  
  /* Optional: Adding hover effect for the switch */
  .switch:hover {
    background: linear-gradient(135deg, #0052cc, #03346E); /* Invert the gradient on hover */
  }
  