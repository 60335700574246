* {
    margin: 0;
    padding: 0;
}

html {
    height: -webkit-fill-available;
}

body {
     display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 100vh;
    height: -webkit-fill-available;
    
}

 .imagecontainer{
    width: 100%;
    height: 100%;
    overflow: hidden;
 }
 .imagecontainer img{
    aspect-ratio: 3/2;
    object-fit: contain;
    height: 100%;
    width:100%;
 }
 .shape {
    width: 100%;
    height: 100%;    
    background-color: orange;
    clip-path: polygon(0% 0%, 0% 100%, 28% 65%, 44% 62%, 100% 100%, 100% 0%);
    margin-top: auto;
    position: relative;
    
  }