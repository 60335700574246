// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
 
$prefix: 'adminlogin';
.#{$prefix}-main {
 height: 400px!important;
 width: 600px!important;
// width: 100%;
 border-radius: 10px!important;
 
 position: relative;
//  border: 1px solid #fff;
  // top: 50%;
  // left: 50%;
  // bottom: auto;
  // transform: 'translate(-50%, -50%)';
  @media only screen and (max-width: 600px) {
    width: 300px!important;
    // margin: auto!important;
    
  }
}

.#{$prefix}-firstcont {
  display:flex;
  flex-direction: column!important;
  background:#fff!important;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  padding:10px;   
  border-top-left-radius: 10px!important;
  border-bottom-left-radius: 10px!important;
  @media only screen and (max-width: 600px) {
    border-radius: 10px!important;    
  }

}

.#{$prefix}-secondcon {
     display:flex;
     flex-direction: column!important;
     background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
     background: linear-gradient(to right, #FF4B2B, #FF416C);
     gap: 10px;
     justify-content: center;
     align-items: center;
     align-content: center;
     width: 100%;
     height: 100%;
     padding:10px;     
     border-top-right-radius: 10px!important;
     border-bottom-right-radius: 10px!important;


     h1{
      color:#fff
     }
     p{
      color:#fff
     }
   
}

.#{$prefix}-logocon {
  width:50px;
  height:50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-self: 3/2;
  }
}

button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
  cursor: pointer;
}
button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

.#{$prefix}-close {
   width:25px;
   height: 25px;
   cursor: pointer;
   padding: 5px;
   text-align: center;
   align-items: center;    
   border-radius: 50%;
   background-color: #FF4B2B;
   color: #fff; 
   border: 1px solid #fff;
   position: absolute;
   top: 10px;
   right: 10px;
   display:flex;
   justify-content: center;   
}