.userstat-main{
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
    justify-content: center;

}

.userstat-icon{
    width:38px;
    height:38px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #4e54c8, #8f94fb);
    padding:10px;

    .icon{
      color:#fff;
    }
  }

  .userstat-heading{
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #34495e;

  }

  .userstat-count{
      margin: 5px 0 0;
      font-size: 20px;
      font-weight: bold;
      color: #2c3e50;
  }

.userstat-activeicon{
    width:38px;
    height:38px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #11998e, #38ef7d);
    padding:10px;

    .icon{
      color:#fff;
    }
  }

  .userstat-expiredicon{
    width:38px;
    height:38px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #f85032, #e73827);
    padding:10px;

    .icon{
      color:#fff;
    }
  }

  .userstat-blockedicon{
    width:38px;
    height:38px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #808080, #a5a5a5);
    padding:10px;

    .icon{
      color:#fff;
    }
  }