.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 0px;
  }
  
  .toggleWrapper {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .toggleButton {
    flex: 1;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1;
    user-select: none;
  }
  
  .toggleButton:hover {
    color: #fff;
  }
  
  .active {
    color: #fff;
  }
  
  .switch {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 33.33%;
    background-color: #03346E;
    transition: transform 0.3s ease;
    z-index: 0;
  }
  