.bottomcon{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;    
    background-color: #fff;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 }
.icons{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}
.iconsize{
  font-size: 30px!important;
}
.iconsize.active {
  /* Active state */
  color: blue;
}
h6.active {
  /* Active state */
  color: blue;
}
.drawerclose{  
  margin-top: auto; /* Push footer to the bottom */
  position: sticky;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topcon{
  position: sticky;
  top: 0;
  z-index: 1000;  
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;  */
  padding: 5px;
  /* margin-bottom: 15px; */
  /* margin-bottom:10px ; */
}
.topcon div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#FFFAE6;
 }
.topcon div span{
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
  font-size: large;
  
}
.sidebaricon{
  width: 100%;
  display: flex;
  align-items: center;
}
.sideimgcon{
  height: 60px;
  width: 60px;
  overflow: hidden;
}
.sideimgcon img{
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  height: 100%;
}