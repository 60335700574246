body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

.userreg-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: aliceblue;
  position: relative;
}

.userreg-con1 {
  width: 100%;
  height: 30vh; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.userreg-con1 svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.userreg-con1 #head {
  z-index: 1;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 2rem;
  top: 20px;
  position: absolute;
}

.userreg-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 20px;
}

.userreg-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  // max-width: 400px;
  gap: 10px;
}

// .userreg-form input {
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// }

.userreg-footer {
  text-align: center;
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
}

.userreg-footer span {
  color: #6661e7;
}

.userreg-footer a {
  color: #FF4B2B;
  font-weight: 700;
  text-decoration: none;
  font-size: large;
}

.footerwavesvg {
  display: block;
  height: 30px;
  width: 100%;
  background-color: transparent;
}

.footerwavepath {
  fill: none;
}

.fgpaswd{
  width: 100%;
  text-align: right;
  color: #6661e7;
  font-style: italic;
  cursor: pointer;
}