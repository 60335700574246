.pageheading{
  font-weight: 600;
  font-size: x-large;
  color:#fff;
  
}
.pcontainer{
  display: flex;
  flex-direction: column;
  gap:4px
}
.pc1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.pc2{
    display: flex;
    flex-direction: column;     
}
.itemname{
 font-size: small;
 font-weight: 600;
}
.catgname{
 font-size:x-small;
 color: gray;
}
.priceheading{
   font-size: small; 
}
.price{
    font-size: small; 
}