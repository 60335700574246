.bckarwcon {
  display: flex;
  align-items: center;
}
.bckarow {
  color: #007667;
  font-size: xx-large !important;
}
.hading {
  align-self: center;
  text-align: left;
  font-weight: 500;
  font-size: large;
  text-decoration: underline;
  font-style: italic;
  padding-left: 10px !important;
}
.btncon {
  display: flex;
  justify-content: end;
  align-items: center;
}

.close{
    display: flex;
    justify-content: center;
    align-items: center;
    width:30px;
    height: 30px;
    background-color: red;
    color:#fff;
    border-radius: 50%;

}

.team-main{
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}